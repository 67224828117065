<template>
  <div class="priceIndex" :class="`priceIndex${type}`">
    <div class="top">
      <img src="@/assets/priceindex_img/logo.png" class="left_img" alt="" />
      <img src="@/assets/priceindex_img/logo2.png" class="right_img" alt="" />
    </div>
    <div class="title">
      <p>新疆煤炭价格指数</p>
      <div class="dayInfo">
        <p class="day">{{ day }}</p>
        <span class="sp">|</span>
        <p class="year">
          <span>{{ dayOfWeek }}</span>
          <span>{{ year }}年{{ month }}月</span>
        </p>
      </div>
    </div>
    <el-table :data="tableData" stripe class="stripeData" :height="type==1?'180px': ''">
      <el-table-column align="center" prop="title" label="指数名称">
      </el-table-column>
      <el-table-column align="center" prop="goodsName" label="本期价格">
        <template slot-scope="scope">
          {{ scope.row.data.currentPrice }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="offerPrice" label="上期价格">
        <template slot-scope="scope">
          {{ scope.row.data.previousPrice }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="offerPrice" label="环比">
        <template slot-scope="scope">
			<span :style="{ color: getColor(scope.row.data.dayQoq) }">
				{{ scope.row.data.dayQoq }}
			</span>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="type==2" class="info">
      <div>
        了解更多资讯/数据/价格，请登录新疆煤炭交易中心官网（https://www.xjcec.com）或关注新疆煤炭交易中心微信公众号查看！
      </div>
      <div class="code">
        <img src="@/assets/priceindex_img/code.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { getPageData } from "@/api/hy.js";
import { number } from 'echarts';
export default {
  data() {
    return {
      year: "",
      month: "",
      day: "",
      dayOfWeek: "",
      tableData: []
    };
  },
  props:{
    type: {
      type: number,
      default: 1
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getDateInfo(date) {
      const now = new Date(date);
      const year = now.getFullYear();
      const month = now.getMonth() + 1; // 月份是从0开始的，所以需要+1
      const day = now.getDate();
      const dayOfWeek = now.getDay();
      const daysOfWeek = [
        "星期天",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];

      return {
        year,
        month,
        day,
        dayOfWeek: daysOfWeek[dayOfWeek], // 转换为中文星期
      };
    },
    formatDate(date) {
      const year = date.toString().slice(0, 4);
      const month = date.toString().slice(4, 6);
      const day = date.toString().slice(6, 8);
      return `${year}-${month}-${day}`;
    },
    getData () {
        getPageData({"sign":"jgzs"}).then(ret =>{
          let list = ret.data.charts.find(e => e.sign == 'XJDLMZXJGZS')
          list.items.forEach(item => {
            item.data = JSON.parse(item.values[0].number)
          });
          this.tableData = list.items
          let date = this.getDateInfo(this.formatDate(list.items[0].data.date));
          this.year = date.year;
          this.month = date.month;
          this.day = date.day;
          this.dayOfWeek = date.dayOfWeek;
          console.log(date,'-----date-----')
          console.log(this.tableData, '-tableData----------ssss----')
        })
    },
	getColor(str) {
      if (str[0] == '+') {
        return 'red';
      } else if (str.length > 1 && str[0] == '-') {
        return 'green';
      } else {
        return '';
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@font-face{
  font-family: 'song';
  src: url('../../../assets/font/songSemiBold.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face{
  font-family: 'hei';
  src: url('../../../assets/font/heiCN-Regular.otf');
  font-weight: normal;
  font-style: normal;
}
.priceIndex {
  background: url("../../../assets/priceindex_img/bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 20px;
  position: relative;
  border-radius: 10px;
  .top {
    display: flex;
    align-content: center;
    justify-content: space-between;
    img {
      width: 150px;
    }
    .left_img{
      position: absolute;
      top: 10px;
      left: 14px;
    }
    .right_img{
      position: absolute;
      top: 10px;
      right: 14px;
    }
  }
  .title {
    display: flex;
    align-items: center;
    margin: 10px 0 10px 0;
    justify-content: center;
    font-family: hei;
    p {
      color: #204C7F;
      font-size: 28px;
      font-weight: bold;
    }
    .dayInfo {
      display: flex;
      align-items: center;
      margin-left: 30px;
      font-family: song;
      p {
        color: #204C7F;
      }
      .sp{
        font-size: 28px;
        color: #204C7F;
        margin: 0 5px;
      }
      .day {
        font-size: 30px;
        font-weight: bold;
      }
      .year {
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        span {
          display: inline-block;
          width: 100%;
        }
      }
    }
  }
  .stripeData {
    background: transparent;
    &::v-deep th{
      .cell{
        background: #4C7A9C !important;
        font-weight: bold;
        color: white !important;  
      }
      background: #4C7A9C !important;
      color: white !important; 
    }
    &::v-deep .el-table__body tr:nth-child(even){
      background-color: #F6F8FA !important;
    }
    &::before{
      content: '';
      position: absolute;
      z-index: 1;
      background-color: transparent !important;
    }
    &::v-deep .el-table__cell{
      padding: 2px 0;
    }
  }
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell{
  background: #F6F8FA !important
}
.priceIndex2{
  height: 100vh;
  width:100%;
  border-radius: 0;
  padding: 15vh 50px 50px 50px;
  
  .dayInfo .day, .dayInfo span, .dayInfo .sp{
    color: #222 !important;
  }
  
  .top {
    img {
      width: 300px;
    }
	.left_img{
	  top: 10vh;
	  left: 28px;
	}
	.right_img{
	  top: 10vh;
	  right: 28px;
	}
  }
  .title {
    margin: 30px 0 30px 0;
    p {
      font-size: 38px;
    }
    .dayInfo {
      .sp{
        font-size: 40px;
      }
      .day {
        font-size: 40px;
      }
      .year {
        font-size: 22px;
      }
    }
  }
  .info{
	font-size: 32px;
    margin-top: 40px;
	.code{
    flex-shrink: 0;
    margin-left: 60px;
    width: 20vh;
    height: 20vh;
    img{
      width: 100%;
      height: 100%;
    }
  }
  }
  .stripeData {
    background: transparent;
    &::v-deep th{
      .cell{
        background: #4C7A9C !important;
        font-weight: bold !important;
        color: white !important;  
        font-size: 25px !important;
      }
      background: #4C7A9C !important;
      color: white !important; 
    }
    &::v-deep .el-table__body tr:nth-child(even){
      background-color: white !important;
    }
    ::v-deep .el-table__body tr.el-table__row--striped td.el-table__cell{
      background: white !important;
    }
    &::before{
      content: '';
      position: absolute;
      z-index: 1;
      background-color: transparent !important;
    }
    &::v-deep .el-table__body{
      border: 1px solid #659BC3;
      border-bottom: none;
    }
    &::v-deep .el-table__row{
      .el-table__cell:last-child{
        border-right: 2px solid #659BC3;
      }
    }
    &::v-deep .el-table__cell{
      color: #659BC3;
      padding: 20px 0;
      border-bottom: 2px solid #659BC3;
      border-right: 2px solid #659BC3;
      font-size: 25px !important;
    }
  }
}
.info{
  font-size: 22px;
  margin-top: 20px;
  color: #204C7F;
  display: flex;
  align-content: space-between;
  align-items: center;
  .code{
    flex-shrink: 0;
    margin-left: 60px;
    width: 120px;
    height: 120px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
</style>